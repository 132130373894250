import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import DatabaseService from "../../services/database.service";
import SetupDataService from "../../services/setup-data.service";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import useLoader from "../../components/LoaderProvider";
import useNotifications from "../../components/NotificationProvider";

const SetupData = () => {
  const [selectedEnv, setSelectedEnv] = useState<any>("");
  const [selectedTenant, setSelectedTenant] = useState<any>("");
  const [envInputValue, setEnvInputValue] = useState("");
  const [tenantInputValue, setTenantInputValue] = useState("");
  const [environments, setEnvironments] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { setNotification } = useNotifications();

  const [selectedModifiedFile, setSelectedModifiedFile] = useState<File | null>(
    null
  );
  const { setLoading } = useLoader();

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(file);
      } else {
        setFile(null);
        setNotification({
          message: "Please upload a valid file format (xlsx or xls)",
          open: true,
          type: "error",
        });
      }
    } else {
      setFile(null);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const databases = await DatabaseService.getDatabases();
        setEnvironments(databases.data.envs);
      } catch (e) {
        console.error("Error", e);
      }
    })();
  }, []);

  const downloadFile = (response: any) => {
    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition 
      ? response.headers["content-disposition"].split("filename=")[1].replace(/"/g, "")
      : response.headers["content-type"].includes("text")
      ? `${selectedTenant.tenant_name}.txt` 
      : `${selectedTenant.tenant_name}.xlsx`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadSpreadSheet = async () => {
    try {
      const options = {
        responseType: "blob",
        headers: { "env-id": selectedEnv.environment_id },
      };
      const response = await SetupDataService.downloadTagsSpreadSheet(
        selectedTenant.tenant_name,
        options
      );
      downloadFile(response);
    } catch (error) {
      console.error("Error downloading the file", error);
      setNotification({
        message: "Error occurred while downloading the file",
        open: true,
        type: "error",
      });
    }
  };

  const handleGenerateNetReport = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      if (selectedFile) {
        formData.append(
          "original_spreadsheet",
          selectedFile,
          selectedFile.name
        );
      }
      if (selectedModifiedFile) {
        formData.append(
          "modified_spreadsheet",
          selectedModifiedFile,
          selectedModifiedFile.name
        );
      }

      const options = {
        headers: {
          "env-id": selectedEnv.environment_id,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await SetupDataService.generateNetReport(
        selectedTenant.tenant_name,
        formData,
        options
      );
      downloadFile(response);
    } catch (error) {
      console.error("Error generating net report", error);
      setNotification({
        message: "Error occurred while generating net report",
        open: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
        <Autocomplete
          value={selectedTenant}
          onChange={(event: any, newValue: string) => {
            if (newValue) {
              setSelectedTenant(newValue);
            }
          }}
          inputValue={tenantInputValue}
          onInputChange={(event, newInputValue) => {
            setTenantInputValue(newInputValue);
          }}
          disablePortal
          id="envs-select-box"
          options={environments}
          sx={{ width: 300 }}
          getOptionLabel={(option) =>
            option.tenant_name ? option.tenant_name : ""
          }
          renderInput={(params) => (
            <TextField {...(params as TextFieldProps)} label="Select Tenant" />
          )}
        />

        <Autocomplete
          value={selectedEnv}
          onChange={(event: any, newValue: string) => {
            if (newValue) {
              setSelectedEnv(newValue);
            }
          }}
          inputValue={envInputValue}
          onInputChange={(event, newInputValue) => {
            setEnvInputValue(newInputValue);
          }}
          disablePortal
          id="envs-select-box"
          options={environments}
          sx={{ width: 300 }}
          getOptionLabel={(option) =>
            option.environment_id
              ? `${option.environment_id}-${option.environment_name}`
              : ""
          }
          renderInput={(params) => (
            <TextField
              {...(params as TextFieldProps)}
              label="Select an environment"
            />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={downloadSpreadSheet}
          disabled={!selectedEnv || !selectedTenant}
        >
          Download Spreadsheet
        </Button>
      </div>
      <div style={{ marginTop: "80px" }}>
        <div>
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={(event) => handleFileChange(event, setSelectedFile)}
            id="file-input"
            style={{ display: "none" }}
          />
          <label htmlFor="file-input" style={{ fontSize: "14px" }}>
            Original file:{" "}
            {selectedFile ? selectedFile.name : "No file selected"}
            <br />
            <Button
              // variant="outlined"
              component="span"
              // color="primary"
              startIcon={<AddIcon />}
              sx={{
                padding: "20px 80px",
                border: "1px dashed grey",
                borderRadius: 0,
                fontSize: "12px",
                textTransform: "none",
                color: "white",
                marginTop: "10px",
              }}
            >
              Drag a file here or click to browse
            </Button>
          </label>
        </div>
        <div style={{ marginTop: "30px" }}>
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={(event) =>
              handleFileChange(event, setSelectedModifiedFile)
            }
            id="modified-file-input"
            style={{ display: "none" }}
          />
          <label htmlFor="modified-file-input" style={{ fontSize: "14px" }}>
            Modified file:{" "}
            {selectedModifiedFile
              ? selectedModifiedFile.name
              : "No file selected"}
            <br />
            <Button
              component="span"
              disableElevation
              startIcon={<AddIcon />}
              sx={{
                padding: "20px 80px",
                border: "1px dashed grey",
                borderRadius: 0,
                fontSize: "12px",
                textTransform: "none",
                color: "white",
                marginTop: "10px",
              }}
            >
              Drag a file here or click to browse
            </Button>
          </label>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "30px" }}
          disabled={
            !selectedFile ||
            !selectedModifiedFile ||
            !selectedEnv ||
            !selectedTenant
          }
          onClick={handleGenerateNetReport}
          // title={(!selectedFile || !selectedModifiedFile) ? "Please select the required field(s) to enable this button." : ""}
        >
          Generate Net Report
        </Button>
      </div>
    </>
  );
};

export default SetupData;
